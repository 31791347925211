import React, { useRef, useEffect } from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import HeroHome3 from "../partials/HeroHome3";
import Process from "../partials/Process";
import Footer from "../partials/Footer";
import Orgs from "../partials/Orgs";
import CTA from "../partials/CTA";
import WhatIf from "../partials/WhatIf";
import Algo from "../partials/Algo";
import Zen from "../partials/Zen";
import GencoinValue from "../partials/GencoinValue";
import ROG from "../partials/ROG";
import { useSearchQuery } from "../hooks";

function Home() {
  const phoneRef = useRef();
  const searchQuery = useSearchQuery();
  const inviterUsername = searchQuery.get("inviterUsername");

  useEffect(() => {
    if (inviterUsername) {
      localStorage.setItem("genInviteUsername", inviterUsername);
    }
  }, [inviterUsername]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="grow">
        {/*  Page sections */}
        {/* {window.location.pathname === "/home-3" ? <HeroHome3 /> : <HeroHome />} */}
        <HeroHome phoneRef={phoneRef} />
        <WhatIf phoneRef={phoneRef} />
        <Process />
        <ROG />
        <GencoinValue />
        <Algo />
        <Zen />
        <Orgs />
        <CTA />
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
