import React from "react";
import GPhone from "../components/animated/GPhone";
import Button from "../components/Button";
import { requestInvite } from "../utils/helpers";

const CTA = () => {
  return (
    <section>
      <div className="flex flex-col  items-center justify-center max-w-6xl mx-auto px-4 sm:px-6 mb-20">
        <Button
          variant="primary"
          className="md:w-3/6 w-full"
          textSize="text-3xl"
          onClick={() => requestInvite()}
        >
          Join Generos
        </Button>
        {/* CTA box */}
        {/* <div
          className="flex justify-start mt-12"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <a
            className="btn md:text-lg rounded-lg font-bold text-primary-0 bg-primary-4 hover:bg-gray-800 w-full  sm:w-auto sm:mb-0"
            href={`${process.env.REACT_APP_GENEROS_APP_URI}/install`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center">
              <GPhone
                className="fill-current text-white w-12 -ml-4"
                heartFill="fill-current text-primary-1"
              />
              <div className="flex flex-col text-left ml-4 leading-tight">
                <div className="text-base font-thin text-white">
                  Install Instructions
                </div>
                <div className="text-2xl font-semibold text-white">
                  Get the App
                </div>
              </div>
            </div>
          </a>
        </div> */}
        {/* <div
          className="flex items-center justify-center rounded-lg w-full mt-6"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div className="flex w-full justify-center">
            <div
              className="flex md:w-4/12 w-full font-extrabold rounded-lg bg-purple-600 hover:bg-purple-700 text-xl p-6 text-topaz-500 cursor-pointer"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_GENEROS_APP_URI}/install`,
                  "_blank"
                );
              }}
            >
              <div className="flex items-center">
                <GPhone
                  className="fill-current text-white w-20 -ml-4"
                  heartFill="fill-current text-white"
                />
                <div className="flex flex-col text-left">
                  <div className="text-3xl font-semibold text-white">
                    Get the App
                  </div>
                  <div className="text-lg font-semibold text-white italic">
                    Join the Generos Community
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default CTA;
