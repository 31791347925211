const validateFields = ({ fields, state }) =>
  fields.every((fieldId) => state[fieldId]);

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

const phoneNumberPattern = [
  { char: /\d/, repeat: 3 },
  { exactly: "-" },
  { char: /\d/, repeat: 3 },
  { exactly: "-" },
  { char: /\d/, repeat: 4 },
];

const generateUnixTimestamp = () => {
  return Math.round(new Date().getTime() / 1000);
};
const requestInvite = () => {
  window.open(
    `${process.env.REACT_APP_GENEROS_APP_URI}/request-invite`,
    "_blank"
  );
};

const joinWaitList = (history) => {
  history.push("/join-waitlist");
};

const showMeTheWay = (history) => {
  if (process.env.REACT_APP_COMMUNITY_LIVE) {
    window.open(
      `${process.env.REACT_APP_GENEROS_APP_URI}/show-me-the-way/greetings`,
      "_blank"
    );
  } else {
    history.push("/request-invite");
  }
};

export {
  validateFields,
  formatPhoneNumber,
  phoneNumberPattern,
  generateUnixTimestamp,
  requestInvite,
  showMeTheWay,
  joinWaitList,
};
