import React from "react";
import { ReactComponent as GencoinGemsIcon } from "../assets/gencoin-gems.svg";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function GencoinValue() {
  // Set up Intersection Observer hook
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1, // Trigger when 100% of the element is visible
  });

  const { ref: mobileRef, inView: mobileInView } = useInView({
    triggerOnce: false,
    threshold: 0.001, // Trigger when 100% of the element is visible
  });

  return (
    <section>
      <div
        className="flex w-full max-w-6xl mx-auto px-4 sm:px-6 items-center"
        data-aos-id-gen-method-anchor
      >
        <div className="pt-20 border-t border-gray-800">
          <div className="">
            {/* Mobile */}
            <div className="md:hidden flex flex-col items-center">
              <div
                className="flex flex-col items-center mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <GencoinGemsIcon className="w-5/6" />

                <div className="flex flex-col text-center items-start justify-center mt-10">
                  <div ref={mobileRef}>
                    {mobileInView && (
                      <div className="h1 mb-6 text-xxl text-white">
                        <CountUp
                          className="text-xxl"
                          end={80}
                          duration={3.5}
                          useEasing={true}
                        />
                        <span className="mr-2">%</span>
                        of All Gem Revenue Powers Gencoin's Value
                      </div>
                    )}

                    {!mobileInView && (
                      <div className="h1 mb-6 text-xxl text-white">
                        <CountUp
                          className="text-xxl"
                          end={80}
                          duration={3.5}
                          useEasing={true}
                        />
                        <span className="mr-2">%</span>
                        of All Gem Revenue Powers Gencoin's Value
                      </div>
                    )}
                  </div>
                  <p className="text-2xl text-white mb-10">
                    Every gem purchased supports the{" "}
                    <span className="font-bold text-primary-1 italic">
                      Generos Redemption Treasury
                    </span>
                    , ensuring Gencoin maintains its {" "}
                    <span className="font-bold">{` 1 US Dollar `}value</span>. Your
                    generosity powers real impact for you and the community.
                  </p>
                </div>
                <div
                  className="flex flex-col items-center justify-center w-full"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <a
                    className="btn text-lg rounded-lg font-bold text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                    href={`${process.env.REACT_APP_GENEROS_DOCS_URI}/user-guides/understanding-value-flow-in-generos-gems-gencoin-and-impact`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>

            {/* Desktop and Tablet */}
            <div className="hidden md:flex flex-col w-full">
              <div
                className="flex mb-36"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <GencoinGemsIcon />

                <div
                  ref={ref}
                  className="flex flex-col items-start justify-center pl-6"
                >
                  {inView && (
                    <div className="h1 mb-2 text-xxl text-white">
                      <CountUp
                        className="text-xxl"
                        end={80}
                        duration={3.5}
                        useEasing={true}
                      />
                      <span className="mr-2">%</span>
                      of All Gem Revenue Powers Gencoin's Value
                    </div>
                  )}
                  {!inView && (
                    <div className="h1 mb-2 text-xxl text-white">
                      <CountUp
                        className="text-xxl"
                        end={80}
                        duration={3.5}
                        useEasing={true}
                      />
                      <span className="mr-2">%</span>
                      of All Gem Revenue Powers Gencoin's Value
                    </div>
                  )}
                  <p className="text-3xl text-white mb-10">
                    Every gem purchased supports the{" "}
                    <span className="font-bold text-primary-1 italic">
                      Generos Redemption Treasury
                    </span>
                    , ensuring Gencoin maintains its {" "}
                    <span className="font-bold">{` 1 US Dollar `}value</span>. Your
                    generosity powers real impact for you and the community.
                  </p>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <a
                      className="btn text-lg rounded-lg font-bold text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                      href={`${process.env.REACT_APP_GENEROS_DOCS_URI}/user-guides/understanding-value-flow-in-generos-gems-gencoin-and-impact`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GencoinValue;
