import React from "react";
// import { ReactComponent as ZenKarma } from "../assets/heroes/zen.svg";
import { ReactComponent as ZenKarma } from "../assets/sensei/zen.svg";
import { useHistory } from "react-router-dom";

// Generosity reinforces positive emotions. In fact, generosity often triggers chemical systems
// in the brain and body that increase pleasure and experiences of reward, reduce stress, and suppress pain,
// which tends to lead to greater happiness and health.

function Zen() {
  const history = useHistory();
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:py-20 border-t border-gray-800">
          {/* Items */}
          <div className="md:grid md:gap-20">
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 flex flex-col justify-center items-center">
              {/* Content */}

              {/* Desktop and Tablet */}
              <div className="max-w-xl md:max-w-none w-full mx-auto md:col-span-7 lg:col-span-6 px-6 hidden md:block">
                <div
                  className="text-xxl h1 mb-8 text-white text-left"
                  data-aos="fade-up"
                  data-aos-id-what-if-anchor
                >
                  Generosity Is Our Superpower!
                </div>
                <div
                  className="text-3xl text-white text-left"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <p className="mb-10">
                    Generosity reinforces positive emotions. In fact, generosity
                    often triggers chemical systems in the brain and body that
                    increase pleasure and experiences of reward, reduce stress
                    and suppress pain.
                  </p>
                  <p className="mb-10">
                    These effects tend to contribute to greater happiness,
                    improved health and some say the
                    <span className="font-barlow font-semibold italic text-primary-1">
                      {" secret to true wealth"}
                    </span>
                    .
                  </p>

                  <div data-aos="fade-up" data-aos-delay="400">
                    <a
                      className="btn text-lg rounded-lg font-bold text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                      onClick={() => history.push("/manifesto")}
                    >
                      Read The Generos Manifesto
                    </a>
                  </div>
                </div>
              </div>
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl justify-center items-center hidden md:block"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <ZenKarma className="w-full md:px-0 px-14" />
              </div>

              {/* Mobile */}
              <div className="md:hidden visible mb-28">
                <div
                  className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <ZenKarma className="md:w-96 w-80" />
                </div>

                <div
                  className="text-4xl h4 mb-8 text-white text-center"
                  data-aos="fade-up"
                  data-aos-id-what-if-anchor
                >
                  <div>Generosity Is Our Superpower!</div>
                </div>
                <div
                  className="text-2xl text-white text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <p className="mb-10">
                    Generosity reinforces positive emotions. In fact, generosity
                    often triggers chemical systems in the brain and body that
                    increase pleasure and experiences of reward, reduce stress
                    and suppress pain.
                  </p>
                  <p className="mb-10">
                    These effects tend to contribute to greater happiness,
                    improved health and some say the
                    <span className="font-barlow font-semibold italic text-primary-1">
                      {" secret to true wealth"}
                    </span>
                    .
                  </p>

                  <div data-aos="fade-up" data-aos-delay="400">
                    <a
                      className="btn text-lg rounded-lg font-bold text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                      onClick={() => history.push("/manifesto")}
                    >
                      Read The Generos Manifesto
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Zen;
