import React from "react";
import { ReactComponent as RogIcon } from "../assets/icons/rog.svg";

function ROG() {
  const potentialPoints = [
    "Each vault can cycle infinitely",
    "There's no limit to how many vaults you can collect",
    "Higher gem tiers unlock even more Gencoin earning potential",
  ];

  return (
    <section>
      <div
        className="flex w-full max-w-6xl mx-auto px-4 sm:px-6 items-center"
        data-aos-id-gen-method-anchor
      >
        <div className="border-t border-gray-800">
          <div className="">
            {/* Mobile */}
            <div className="md:hidden flex flex-col items-center mt-10">
              <div
                className="flex flex-col items-center mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <RogIcon className="w-5/12 text-primary-1 fill-current" />

                <div className="flex flex-col text-center items-start justify-center mt-10">
                  <div className="h1 mb-6 text-xxl text-white">
                    Generate A Return On Generosity
                  </div>
                  <p className="text-2xl text-white mb-8">
                    Experience the power of giving that generates. Starting with
                    just a <span className="font-bold text-white">$5</span> gem
                    purchase, you'll earn your first Ruby vault that generates
                    10 Gencoin{" "}
                    <span className="font-bold text-white">
                      ($10 USD value)
                    </span>{" "}
                    when it cycles — a{" "}
                    <span className="font-bold text-emerald-500">100% ROG</span>
                    . And the potential only grows from there:
                  </p>

                  <ul className="text-2xl text-white mb-8 space-y-4 list-none pl-4">
                    {potentialPoints.map((point, index) => (
                      <li
                        key={index}
                        className="flex items-start justify-start text-left"
                      >
                        <span className="text-primary-1 mr-2">•</span>
                        {point}
                      </li>
                    ))}
                  </ul>

                  <p className="text-2xl text-primary-1 font-bold mb-6 italic">
                    Your potential grows with each act of generosity.
                  </p>
                </div>
              </div>
            </div>

            {/* Desktop and Tablet */}
            <div className="hidden md:flex flex-col w-full mt-20">
              <div
                className="flex mb-36"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="flex flex-col items-start justify-center mr-16">
                  <div className="h1 mb-6 text-xxl text-white">
                    Generate A Return On Generosity
                  </div>
                  <p className="text-3xl text-white mb-8">
                    Experience the power of giving that generates. Starting with
                    just a <span className="font-bold text-white">$5</span> gem
                    purchase, you'll earn your first Ruby vault that generates
                    10 Gencoin{" "}
                    <span className="font-bold text-white">
                      ($10 USD value)
                    </span>{" "}
                    when it cycles — a{" "}
                    <span className="font-bold text-emerald-500">100% ROG</span>
                    . And the potential only grows from there:
                  </p>

                  <ul className="text-3xl text-white mb-8 space-y-4 list-none pl-4">
                    {potentialPoints.map((point, index) => (
                      <li key={index} className="flex items-start">
                        <span className="text-primary-1 mr-2">•</span>
                        {point}
                      </li>
                    ))}
                  </ul>

                  <p className="text-3xl text-primary-1 font-bold mb-6 italic">
                    Your potential grows with each act of generosity.
                  </p>
                </div>
                <RogIcon className="w-5/6 text-primary-1 fill-current" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ROG;
